import React, { useEffect } from 'react'

import SEO from '../../components/seo'
import CertificationsContent from '../../components/Certifications/Certifications'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
// import { CERTIFICATIONS } from '../components/download-links'
import { CERTIFICATIONS as CERTIFICATIONS_LINK } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import defaultFaqs from '../../components/FAQs/faqs-list'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Certifications',
    url: CERTIFICATIONS_LINK,
  },
]

const Certifications: React.FC = () => {
  return (
    <div>
      <SEO
        title="View the RNG, AIGF, And Poker Game Fairness Certificate"
        description="Discover the certifications that make Mega the safest platform to play on. Learn about Mega's RNG certification and membership with the AIGF"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(defaultFaqs)}
      />
      <CertificationsContent showFaqs={false} title="100% Certified Platform" />
    </div>
  )
}

export default Certifications
